const headers = [
  { text: "STT", align: "center", sortable: true,value: "index",width:"auto",class:"primary secondary--text text-center" },
  { text: "Tên", align: "center", sortable: true,value: "Title",width:"auto",class:"primary secondary--text text-center" },
  { text: "Url", align: "center", sortable: true, value: "Url",width:"auto",class:"primary secondary--text text-center" },
  { text: "Email", align: "center", sortable: true, value: "Email",width:"auto",class:"primary secondary--text text-center" },
  { text: "Trạng thái", align: "center", sortable: true, value: "Status",width:"auto",class:"primary secondary--text text-center" },
  { text: "Thời gian", align: "center", sortable: true, value: "CampEnd",width:"auto",class:"primary secondary--text text-center" },
  { text: "Tổng Order", align: "center", sortable: true, value: "Orders",width:"auto",class:"primary secondary--text text-center" },
  { text: "Order Cuối", align: "center", sortable: true, value: "Last.Orders",width:"auto",class:"primary secondary--text text-center" },
  { text: "Payout Cuối", align: "center", sortable: true, value: "Last.Payout",width:"auto",class:"primary secondary--text text-center" },
  { text: "Balance Cuối", align: "center", sortable: true, value: "LastBalance",width:"auto",class:"primary secondary--text text-center" },
  { text: "Tài khoản", align: "center", sortable: true, value: "TaiKhoan",width:"auto",class:"primary secondary--text text-center" },
  { text: "Accounts", align: "center", sortable: true, value: "Accounts",width:"auto",class:"primary secondary--text text-center" },
  { text: "Thao tác", align: "center", sortable: true, value: "controller",width:"auto",class:"primary secondary--text text-center" },
]

export default {
  headers,
}
<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          TEESPRING
        </span>
      </v-card-title>
      <v-card-text>
        <Datatables
          :value.sync="Selected"
          :headers="headers"
          :items="itemsWithIndex"
          :loading="loading"
          :group="false"
          multiple
        >
          <template #tools>
            <template v-if="Selected.length > 0">
              <v-btn
                  class="mx-2"
                  color="orange"
                  @click="doMoveListOpen"
              >
                Chuyển ({{MoveList.length}})
              </v-btn>
            </template>
            <v-col
              cols="auto"
            >
              <v-select
                v-model="Filter.Account"
                :items="Users"
                item-text="email"
                item-value="_id"
                label="Lọc bởi tài khoản"
              />
            </v-col>      
            <v-col
              cols="auto"
            >
              <v-select
                v-model="Filter.Status"
                :items="StatusList"
                item-text="text"
                item-value="val"
                label="Lọc bởi trạng thái"
              />
            </v-col>    
            <v-col
              cols="auto"
            >
              <v-select
                v-model="Filter.Proxy"
                :items="ProxyStatusList"
                item-text="text"
                item-value="val"
                label="Lọc bởi Proxy"
              />
            </v-col>  
            <v-col
              cols="auto"
            >
              <v-checkbox
                v-model="Filter.NoOrder"
                label="Chưa có giao dịch ?"
                color="green"
              />
            </v-col>    
          </template>
          <template #[`item.Status`]="{ item }">
            <span
              :class="`${TeespringStatus[item.Status].c}--text text-h6`"
            >
              {{TeespringStatus[item.Status].t}}
            </span>
            <div class="text-body2 blue--text" v-if="item.Status === 'cho_thanh_toan' && item.Last.Request">
              {{TimeAgo(item.Last.Request)}}
            </div>
          </template>
          <template #[`item.LastBalance`]="{ item }">
            <span class="text-h6 red--text" v-if="!item.LastBalance">Chưa</span>
            <span class="text-h6 blue--text">{{item.LastBalance}}</span>
          </template>
          <template #[`item.Orders`]="{ item }">
            <span class="text-h6 blue--text">{{item.Orders}}</span>
          </template>
          <template #[`item.Last.Orders`]="{ item }">
            <span class="text-h6 blue--text">{{item.Last.Orders}}</span>
          </template>
          <template #[`item.Last.Payout`]="{ item }">
            <span v-if="item.Last.Payout === 0" class="text-h6 red--text">Chưa</span>
            <span v-else class="text-h6 blue--text">{{ThoiGian(item.Last.Payout)}}</span>
          </template>
          <template #[`item.Accounts`]="{ item }">
            <p class="text-body blue--text"><strong class="white--text">Tạo:</strong> {{item.Create.email}}</p>
            <p class="text-body blue--text"><strong class="white--text">Work:</strong> {{item.Access.email}}</p>
          </template>
          <template #[`item.CampEnd`]="{ item }">
            <template v-if="item.CampEnd">
              <strong>End:</strong> {{ThoiGian(item.CampEnd)}}
              <br>
              <span v-if="item.CampEndLast"><strong>Last:</strong> {{ThoiGian(item.CampEndLast)}}</span>
            </template>
            <span v-else class="text-h6 orange--text">Chưa có dữ liệu</span>
          </template>
          <template #[`item.TaiKhoan`]="{ item }">
              <Dialog 
                title="Xem tài khoản"
                text="Xem"
                dark
              >
                <v-row>
                  <v-col
                    cols=6
                  >
                    <v-text-field
                      :value="item.Email"
                      :readonly="true"
                    />

                  </v-col>
                  <v-col
                    cols=6
                  >
                    <v-text-field
                      :value="item.Password"
                      :readonly="true"
                    />

                  </v-col>
                </v-row>
              </Dialog>
          </template>
        </Datatables>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="Box.Payout"
      max-width="600px"
      persistent
      ransition="dialog-bottom-transition"
    >
      <Header
        :title="`Payout Camp: ${PayoutData.Title}`"
        :close.sync="Box.Payout"
      >
          <v-card-title>Payout Camp: {{PayoutData.Title}}</v-card-title>
          <v-card-text>
            <v-form
              v-model="Valid.Payout"
            >
            <v-row>
              <v-col
                cols="6"
              >
                <v-currency-field
                  v-model="PayoutData.amount"
                  :rules="[required('Số tiền')]"
                  label="Số tiền"
                />
              </v-col>
            </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green"
              @click="doPayout"
            >
              Thanh toán
            </v-btn>
          </v-card-actions>
      </Header>
      <loading :status="Loader.Payout" />
    </v-dialog>
    <v-dialog
      v-model="Box.Login"
      max-width="600px"
      persistent
      ransition="dialog-bottom-transition"
    >
      <Header
        :title="`Login Camp: ${CampData.Title}`"
        :close.sync="Box.Login"
      >
          <v-card-title>Login Camp: {{CampData.Title}}</v-card-title>
          <v-card-text>
            <div class="pa-2">
              <v-text-field
                v-model="CampData.Balance"
                label="Balance - Bấm đăng nhập để xem"
                :disabled="true"
              />
            </div>
            <br />
            <v-row>
              <v-col
                cols="auto"
              >
              <v-btn
                color="blue"
                @click="doLogin('login')"
              >
                Login
              </v-btn>
              </v-col>
              <v-col
                cols="auto"
              >
                <v-btn
                  v-if="CampData.Cookies"
                  color="blue"
                  @click="doLogin('session')"
                >
                  Login w Session
                </v-btn>
              </v-col>
              <v-col
                cols="auto"
              >
                <v-btn
                  v-if="CampData.Balance && CampData.Balance !== '$0.00'"
                  color="green"
                  @click="doRequestPayout()"
                >
                  Request Payout
                </v-btn>
              </v-col>
            </v-row>
            <br />
            <v-divider />
            <v-checkbox
              v-if="CampData.Cookies"
              v-model="CampData.VerifyPaypal"
              color="green"
              label="Xác nhận Paypal"
            />
            <template v-if="CampData.VerifyPaypal">
              <div class="pa-2">
                <v-row>
                  <v-col
                    cols="auto"
                  >
                    <v-btn
                      color="blue"
                      small
                      @click="doVerifyPaypal()"
                    >
                      Y/cầu xác thực PayPal
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                  >
                    <v-btn
                      color="orange"
                      small
                      @click="doFetchVerifyPaypalMail(CampData.Email)"
                    >
                      Check Mail
                    </v-btn>         
                  </v-col>  
                </v-row>
                <br />
                <v-text-field
                  v-model="CampData.Link"
                  label="Link Verify"
                />
                <v-btn
                  color="green"
                  small
                  @click="doRunEmail()"
                  :disabled="!CampData.Link"
                >
                  Xác thực PayPal
                </v-btn>
              </div>
            </template>
          </v-card-text>
      </Header>
      <loading :status="Loader.Login" />
    </v-dialog>
    <Modal
      :title="`Bind IP: ${CampData.Title}`"
      :onClose.sync="Box.BindIP"
      :loader.sync="Loader.BindIP"
    >
        <template #body>
          <v-form v-model="Valid.BindIP">
            <v-autocomplete
              v-model="CampData.Proxy"
              :items="proxyDetails"
              item-value="_id"
              item-text="Text"
              :rules="[required('Proxy')]"
              label="Proxy"
            />
          </v-form>
        </template>
        <template #submit>
          <v-btn
            color="green"
            :disabled="!Valid.BindIP"
            @click="doSetProxy"
          >
            Cập nhật
          </v-btn>
        </template>
    </Modal>
    <Modal
        title="Chuyển tài khoản"
        :onClose.sync="Box.Move"
        :loader.sync="Loader.Move"
    >
      <template #body>
        <v-select
            v-model="Create"
            :items="UserList"
            item-value="_id"
            item-text="email"
            label="Tài khoản Create"
        />
        <v-select
            v-model="Access"
            :items="UserList"
            item-value="_id"
            item-text="email"
            label="Tài khoản Access"
        />
      </template>
      <template #submit>
        <v-btn
            color="green"
            @click="doMoveList"
            :disabled="(!Create && !Access) || UserList.length === 0 || MoveList.length === 0"
        >
          Chuyển
        </v-btn>
      </template>
    </Modal>
    <Modal
        :title="`Lịch sử giao dịch: ${CampData.Title}`"
        :onClose.sync="Box.PayoutHistory"
        :loader.sync="Loader.PayoutHistory"
    >
      <template #body>
        <div v-html="CampData.History" />
      </template>
    </Modal>

    <loading :status="loading" />
  </div>
</template>

<script>
import Header from '@/components/base/pagehead.vue'
import Datatables from '@/components/base/datatables.vue'
import loading from '@/components/base/loading.vue'
import Dialog from '@/components/base/Dialog.vue'
import Modal from '@/components/base/modal.vue'
import {mapGetters} from 'vuex'
import Teespring from '@/modules/teespring.js';
import Accounts from  '@/modules/account.js';
import {ProxyHelper} from  '@/modules/proxy.js';
import { Toast, DoiTien, ThoiGian,TimeAgo,ThongBao } from '@/helpers'
import {FindInArray} from '@/helpers/arrays.js'
import { TeespringStatus } from '@/helpers/status'
import validations from '@/helpers/validations'
import sha256 from 'crypto-js/sha256';
import  headers from '@/config/headers/teespring/admin.js'
import TSClientHelper from '@/helpers/teespring/client.js'
import '@/main.js';
const ClientService = new TSClientHelper();
export default {
  components: {
    loading,
    Datatables,
    Header,
    Dialog,
    Modal,
  },
  data () {
    return {
      ...headers,
      Selected:[],
      loading: false,
      checking: false,
      checker: undefined,
      hashed: undefined,
      items: [],
      addData: {},
      PayoutData:{
        id:undefined,
        amount:0,
      },
      CampData:{

      },
      Filter:{
        Account:null,
        Status:'not_die',
        NoOrder:false,
        Proxy:'all',
      },
      Box: {
        Add:false,
        Payout: false,
        Login: false,
        BindIP:false,
        Move: false,
        PayoutHistory: false,
      },
      Valid:{
        Add: false,
        Payout: false,
        BindIP: false,
      },
      Loader:{
        Add: false,
        Payout: false,
        Login:false,
        BindIP: false,
        Move: false,
        PayoutHistory: false,
      },
      Users:[],
      StatusList:[{'val':'all','text':'Tất cả'},{'val':'not_die','text':'Ngoại trừ Die'}].concat(Object.keys(TeespringStatus).map(key=>({
        'val':key,
        'text':TeespringStatus[key].t
      }))),
      ProxyStatusList:[{'val':'all','text':'Tất cả'},{'val':1,'text':'Đã set'},{'val':2,'text':'Chưa set'}],
      Proxies:[],
      UserList:[],
      Create:undefined,
      Access:undefined,
      TeespringStatus,
    }
  },
  computed:{
    ...mapGetters(['getUser']),
    MoveList(){
      return this.Selected.map(item=>{return item._id})
    },
    AutoURL(){
      if(this.addData.Url){
        const Url = this.addData.Url.split('/')
        const UrlFormat = Url[Url.length-1]
        return `Địa chỉ sẽ là: ${UrlFormat}`
      }
      return 'Điền địa chỉ !!!'
    },
    proxyDetails(){
      return this.Proxies.map(item=>({
        ...item,
        Text:`${item.proxy_address} - ${item.country_code} - ${item.city_name} - ${item.country_code_confidence}`,
      }));
    },
    itemsWithIndex(){
      return this.items.map(
        (items, index) => {
          const {Account,Status,NoOrder,Proxy} = this.Filter;
          if(Account && (items.Create._id !== Account || items.Access._id !== Account)) return undefined;
          if(Status === 'not_die' && items.Status === 'die') return undefined;
          if(Status !== 'all' && Status !== 'not_die' && (items.Status !== Status)) return undefined;
          if(NoOrder && (items.Last.Orders > 0)) return undefined;
          if(Proxy !== 'all'){
            if(Proxy === 1 && !items.Proxy) return undefined;
            if(Proxy === 2 && items.Proxy) return undefined;
          } 
          return {
            ...items,
            index: index + 1,
            SoTien: (items.Status === 'rut') ? '-'+items.SoTien:items.SoTien,
            controller:this.controller(items),
          }
        }).filter(v=>!!v)
    }
  },
  methods:{
    ...validations,
    DoiTien,
    ThoiGian,
    TimeAgo,
    doGetPayoutHistory:ClientService.doGetPayoutHistory,
    controller(item){
      let controller = [
        {t:'Kiểm tra',c:'green',a:this.doCheck, i:'mdi-update'},
        {t:'Payout',c:'green',a:this.doPayoutPopup, i:'mdi-currency-usd'},
        {t:'Login',c:'blue',a:this.doLoginOpen, i:'mdi-currency-usd'},
      ]
      if(item.Status === 'da_xu_ly'){
        controller.push({t:'Chờ Pay',c:'blue lighten-3',a:this.doWaiting, i:'mdi-update'})
      }
      if(item.Cookies){
        controller.push({t:'History',c:'blue darken-1',a:this.doGetPayoutHistory, i:'mdi-history'})
      }
      if(!item.Proxy){
        controller.push({t:'Proxy',c:'indigo',a:this.doProxyOpen, i:'mdi-leak'})
      }else{
        controller.push({t:'Gỡ Proxy',c:'indigo',a:this.doRemoveProxy, i:'mdi-leak'})
      }
        controller.push({t:'Cộng Order',c:'orange',a:this.doAddOrderManual, i:'mdi-basket-plus'});
      return controller
    },
    doMoveList(){
      this.Loader.Move = true;
      const {Create,Access} = this;
      Teespring.doMoveAccountOwner({Accounts:this.MoveList,Create,Access}).then(resp=> {
        if(resp.success) {
          this.Box.Move = false;
          this.Selected = [];
          this.Create = undefined;
          this.Access = undefined;
          this.doDownload();
        }
      }).finally(()=>{
        this.Loader.Move = false;
      });
    },
    doMoveListOpen(){
      this.Box.Move = true;
      if(this.UserList.length === 0){
        this.Loader.Move = true;
        Accounts.GetAll().then(resp=>{
          if(resp.data) this.UserList = resp.data
        }).finally(()=>{
          this.Loader.Move = false;
        })
      }
    },
    doProxyDownload(){
      this.Loader.BindIP = true;
      ProxyHelper().then(resp=>{
        const {data} = resp;
        if(data){
          this.Proxies = data;
        }
      }).finally(()=>{
        this.Loader.BindIP = false;
      })
    },
    doProxyOpen(item){
      this.CampData = Object.assign({},item);
      this.Box.BindIP = true;
      this.doProxyDownload();
    },
    doRunEmail(){
      const {_id,Link} = this.CampData;
      if(!_id || !Link) return ThongBao.Error('Dữ liệu không hợp lệ !!!');
      Teespring.doVerifyEmail({id:_id,Link}).finally(()=>{
        this.Loader.BindIP = false;
      })
    },
    doFetchVerifyPaypalMail(email){
      Teespring.doFetchEmail({email,type:'paypal'}).then(resp=>{
        const {data} = resp;
        if(data){
          this.$set(this.CampData,'Link',data);
        }else{
          setTimeout(()=>{
            this.doFetchVerifyPaypalMail(Email)
          },1000);
        }
      }).catch(()=>{
        setTimeout(()=>{
          this.doFetchVerifyPaypalMail(Email)
        },1000);
      });
    },
    doVerifyPaypal(){
      const {_id,Email} = this.CampData;
      this.Loader.Login = true;
      Teespring.doVerifyPaypal({id:_id}).then(resp=>{
        if(resp.data === 500){
          ThongBao.Error('Không thể yêu cầu xác thực Paypal. Lỗi Server !!!');
          return;
        }
        setTimeout(()=>{
          this.doFetchVerifyPaypalMail(Email)
        },1000);
      }).finally(()=>{
        this.Loader.Login = false;
      })
    },
    async doRemoveProxy(item){
      const xac_nhan = await ThongBao.XacNhan("Có đồng ý gỡ Proxy ?");
      if(xac_nhan){
        this.loading = true;
        Teespring.RemoveProxy({id:item._id}).then(resp=>{
          if(resp.success){
            this.checking = true;
            this.doDownload();
          }
        });
      }
    },
    doSetProxy(){
      const {_id,Proxy} = this.CampData;
      if(!_id || !Proxy) return ThongBao.Error('Dữ liệu không hợp lệ !!!');
      this.Loader.BindIP = true;
      Teespring.SetProxy({id:_id,Proxy}).then(resp=>{
        if(resp.success){
          this.Box.BindIP = false;
          this.checking = true;
          this.doDownload();
        }
      }).finally(()=>{
        this.Loader.BindIP = false;
      })
    },
    doLoginOpen(item){
      this.CampData = Object.assign({},item);
      this.Box.Login = true;
    },
    doLogin(method){
      this.Loader.Login = true;
      const {_id} = this.CampData;
      Teespring.doLogin({id:_id,method}).then(resp=>{
        const {data} = resp;
        if(data){
          this.CampData.Balance = data
        }
      }).finally(()=>{
        this.Loader.Login = false;
      })
    },
    doRequestPayout(){
      this.Loader.Login = true;
      const {_id} = this.CampData;
      Teespring.doPayout({id:_id}).then(resp=>{
        const {data} = resp;
        if(data){
          if(data !== 500){
            this.Box.Login = false;
            this.checking = true;
          }else{
            ThongBao.Error('Lỗi 500 Request payout');
          }
        }
      }).finally(()=>{
        this.Loader.Login = false;
      })
    },
    doWaiting(item){
      const index = FindInArray(this.items,item._id,'_id');
      this.loading = true
      Teespring.SetWait({id:item._id}).then(resp=>{
        if(resp.success) this.items[index].Status = 'cho_thanh_toan';
      }).finally(()=>{
        this.loading = false;
      })
    },
    doPayoutPopup(item){
      this.PayoutData = Object.assign({}, {
        id:item._id,
        Title: item.Title,
        amount: 0,
      });
      this.Box.Payout = true;
    },
    doPayout(){
      this.Loader.Payout = true;
      Teespring.Payout(this.PayoutData).then(this.Callback).finally(()=>{
        this.Loader.Payout = false;
      });
    },
    AccountOwner(item){
      if(item.Create.id === this.getUser.id) return true;
      return false;
    },
    doOpenBoxAdd(){
      this.addData = {};
      this.Box.Add = true;
    },
    Callback(resp){
      if(resp.success){
        this.doDownload();
        this.Box.Add = false;
        this.Box.Payout = false;
      }
      this.loading = false;
    },
    doDownload(){
      this.loading = true;
			this.Selected = [];
      Teespring.GetAll().then(json=>{
        const {data} = json
        if(data){
          const hashed = sha256(JSON.stringify(data));
          if(hashed !== this.hashed){
            console.log('Đã cập nhật dữ liệu mới !!!');
            this.checking = false;
          }
          this.items = data
        }else{
          Toast.Error('Không thể tải dữ liệu !!!');
        }
      })
      .finally(()=>{
        this.loading = false;
      })
    },
    doDownloadUser(){
      Accounts.GetAll().then(resp=>{
        const {data} = resp;
        if(data) this.Users = [{'_id':null, 'email':'Tất cả'}].concat(data);
      })
    },
    doAddOrderManual(item){
      this.loading = true;
      Teespring.doAddOrderManual({id:item._id}).then(()=>{
        this.doDownload();
      }).finally(()=>{
        this.loading = false;
      })
    }
  },
  mounted(){
    this.loading = true;
    Promise.all([this.doDownload(),this.doDownloadUser()]).finally(()=>{
      this.loading = false;
    })
    this.checker = setInterval(() => {
      if(this.checking === true){
        this.doDownload()
      }
    }, 10000);
  },
  beforeDestroy(){
    clearInterval(this.checker);
    this.checker = undefined;
  }
}
</script>

<style>

</style>